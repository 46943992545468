<template>
  <div class="wrapper d-flex justify-center" >
    <splide :options="options" :slides = 'slides'  style="width: 100%">
      <splide-slide v-for="slide  in slides" :key="slide.id">
        <img @click="search_banner(slide.tag_id)" :width="imageWidth" :height="imageHeight" :src="slide.image" />
      </splide-slide>
    </splide>
  </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { mapState } from 'vuex';
import store from '@/store';
import { search_product_tag } from '@/views/shop/search/models/search';
  export default { 
  components: {
    Splide,
    SplideSlide,
  },
  props: ['options','imageWidth', 'imageHeight'],
    computed: {
      ...mapState({
        slides: state => state.home.brands,
        search_fild: state => state.search.searchFild
      })
    },
    mounted () {
      for (const image of this.slides) {
      caches.open('banner').then(cache => {
      cache.add(image.image)
     })
    }
    },
   methods: {
     search_banner (tag_id) {
          const filters = {}
          filters.page = 1
          filters.tags = tag_id
          localStorage.setItem('filter_search', JSON.stringify(filters))
          search_product_tag(filters)
    }
     }
   }
</script>